* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.login_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    
}

.wrapper_container {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    margin-top: 20px;
}

.wrapper_container h2 {
    text-align: center;
    margin-bottom: 15px;
    font-weight: 600;
}

label {
    font-size: 14px;
}

input {
    width: 350px;
    height: 35px;
    border-radius: 3px;
    padding: 10px;
    outline: none;
    font-size: 15px;
    margin-top: 5px;
    border: 1px solid #1e90ff;
}

.forgot {
    margin-top: 10px;
    width: 100%;
    font-size: 13px;
    justify-content: end;
    text-align: right;
    margin-bottom: 10px;
}

.login_button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px 0px;
    border-radius: 5px;
    outline: none;
    font-size: 15px;
    background-color: #FC9334;
    border: none;
    width: 50%;
    color: #ffffff;
    cursor: pointer;
    margin-top: 10px;
}

.account_wrapper {
    text-align: center;
    margin-top: 10px;
}
.account {
    font-size: 13px;
}





@keyframes slideLeft {
    from {
      transform: translateX(50%);
    }
    to {
      transform: translateX(0%);
    }
  }
  
  .animate-slideLeft {
    animation: slideLeft 0.3s ease-out;
  }