@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.css-dev-only-do-not-override-3mqfnx.ant-rate {
  color: #8d73d9;
  display: flex;
}
.ant-btn-primary {
  background-color: #1677ff !important;
}
.content {
  padding: 50px !important;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.demo-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
.quill-wrapper {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: max-content;
}
.rdw-editor-toolbar {
  border-bottom: 1px solid #1677ff !important;
}
.quill-editor{
  padding: 8px;
  /* border-top: 1px solid #d9d9d9; */
}
.ant-select-item-option {
  padding: 8px 12px !important;
}
.ant-popover-inner-content {
  display: flex;
  gap: 3px;
}
@layer base {
  :root {
  }
}

.ant-btn-primary {
  background-color: #ffb319 !important;
}


@keyframes rotateAndTranslate { 
  0% {
    transform: rotate(0) 
    translateX(0);
  }
  100% { 
    transform: rotate(10deg) 
    translateX(50px); 
  } 
} 
@keyframes rotateAndTranslatey { 
  0% {
    transform: rotate(30deg) 
    translateY(50px);
  }
  100% { 
    transform: rotate(0deg) 
    translateY(0px); 
  } 
} 
.animated-element { 
  animation: rotateAndTranslate 1s ease-in-out;
  animation-fill-mode: forwards 
 }
 .animated-back-element { 
  animation: rotateAndTranslatey 1s ease-out;
  animation-fill-mode: backwards 
 }