* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.forget_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.wrapper_container {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    /* padding: 20px; */
}

label {
    font-size: 14px;
}

p {
    font-size: 14px;
}

input {
    width: 350px;
    height: 35px;
    border-radius: 3px;
    padding: 10px;
    outline: none;
    font-size: 15px;
    margin-top: 5px;
    border: 1px solid #1e90ff;
}

.forget_button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px 15px;
    border-radius: 5px;
    outline: none;
    font-size: 15px;
    background-color: #FC9334;
    border: none;
    width: 100%;
    color: #ffffff;
    cursor: pointer;
    margin-top: 24px;
}